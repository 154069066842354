<template>
  <div class="d-card p-3">
    <div
      class="image-holder"
      :style="{
        backgroundImage: 'url(' + image + ')',
        height: imageHeight,
        backgroundColor: bgColor,
        '--borderColor': borderColor,
      }"
    ></div>
    <router-link
      v-if="link"
      v-slot="{ navigate }"
      :to="link"
      custom
    >
      <a
        href="javascript:void(0)"
        class="text-default"
        @click="navigate"
      >
        <h3
          class="mb-0 d-flex justify-content-between px-3 py-4 text-uppercase"
          style="letter-spacing: 2px; color: #A783FF; font-weight: 700"
        >
          <span style="width: 70%; display: inline-block">{{ text }}</span>
          <span class="next-arrow-purple"></span>
        </h3>
      </a>
    </router-link>

    <a
      v-if="url"
      :href="url"
      target="_blank"
      class="text-default"
    >
      <h3
        class="mb-0 d-flex justify-content-between px-3 py-4 text-uppercase"
        style="letter-spacing: 2px; color: #A783FF; font-weight: 700"
      >
        <span style="width: 70%; display: inline-block">
          {{ text }}
        </span>
        <span class="next-arrow-purple"></span>
      </h3>
    </a>
  </div>
</template>

<script>
export default {
  props: {
    image: {
      type: String,
    },
    imageHeight: {
      type: String,
      default: '270px',
    },
    link: {
      type: String,
      default: '',
    },
    url: {
      type: String,
    },
    text: {
      type: String,
    },
    bgColor: {
      type: String,
    },
    borderColor: {
      type: String,
    },
  },
};
</script>

<style lang="scss" scoped>
.d-card {
  position: relative;
  border: 2px solid rgba(153, 153, 153, 0.4);
  border-radius: 25px;
  height: 100%;

  .image-holder {
    border-radius: 25px 25px 0 0;
    position: relative;
    background-size: cover;
    background-position: center;
    margin-bottom: 1rem;
  }

  .heading {
    display: flex;
    padding: 0.3em 0;
    min-height: 60px;
    justify-content: space-between;
    align-items: center;
    font-size: 1.5em;
    line-height: 1.2;
  }
}
</style>
