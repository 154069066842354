<template>
  <b-col cols="12">
    <div class="timeline-box mt-5 text-center">
      <h2
        class="display-4 font-weight-normal"
        style="margin-bottom: 2.5rem"
      >
        What's next?
      </h2>
      <!-- Large viewports -->
      <Carousel
        :per-page="1"
        pagination-color="#d9d9d9"
        pagination-active-color="#828282"
        :pagination-size="15"
        class="d-none d-lg-block"
      >
        <Slide
          v-for="(group, groupIndex) in timelineDisplay"
          :key="`group-${groupIndex}`"
        >
          <ul
            class="text-left"
            :class="['list-unstyled timeline', show ? 'show-all' : 'hide-some']"
          >
            <li
              v-for="(list, i) in timelineDisplay[groupIndex]"
              :key="i"
              :class="list.done ? 'done' : ''"
            >
              <b-icon
                v-if="list.done"
                icon="check-circle-fill"
              />
              <div class="list-content">
                <h2 class="med-font mt-4 mb-3">
                  {{ list.title }}
                </h2>
                <span
                  v-if="list.after"
                  class="text-muted small-text"
                >{{
                  list.after
                }}</span>
                <p class="sub-text mt-2 mb-1 px-4">
                  {{ list.desc }}
                </p>
              </div>
            </li>
          </ul>
        </Slide>
      </Carousel>

      <!-- Small viewports -->
      <Carousel
        :per-page="1"
        pagination-color="#d9d9d9"
        pagination-active-color="#828282"
        :pagination-size="15"
        class="d-block d-lg-none"
      >
        <Slide
          v-for="(item, index) in timeline"
          :key="`mobile-${index}`"
        >
          <ul
            class="list-unstyled mobile-timeline"
            :class="[index == 0 ? 'first' : '', 
                     index + 1 == timeline.length ? 'last' : '']"
          >
            <li
              :class="[
                item.done ? 'done' : '', 
                
              ]"
            >
              <b-icon
                v-if="item.done"
                icon="check-circle-fill"
              />
              <div class="list-content">
                <h2 class="med-font mt-5 mb-3">
                  {{ item.title }}
                </h2>
                <span
                  v-if="item.after"
                  class="text-muted small-text"
                >{{
                  item.after
                }}</span>
                <p class="sub-text mt-2 mb-1 px-4">
                  {{ item.desc }}
                </p>
              </div>
            </li>
          </ul>
        </Slide>
      </Carousel>
    </div>
  </b-col>
</template>

<script>
import { Carousel, Slide } from 'vue-carousel';

export default {
  components: {
    Carousel,
    Slide,
  },
  props: {
    timeline: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    timelineDisplay() {
      const arr = [];
      for (let i = 0; i < this.timeline.length; i += 5) {
        const chunk = this.timeline.slice(i, i + 5);
        arr.push(chunk);
      }
      return arr;
    },
  },
};
</script>

<style lang="scss" scoped>
$size: 20px;

.timeline-box {
  padding: 2rem 2rem 0;
  overflow: hidden;

  @include media-breakpoint-down(md) {
    padding: 0;
  }

  @include media-breakpoint-up(xxl) {
    padding: 2rem * $scale-font 2rem * $scale-font;
  }
}

.mobile-timeline {
  display: flex;
  justify-content: center;
  overflow: hidden;

  li {
    z-index: 1;
    display: flex;
    flex-direction: column;
    text-align: center;
    margin: 0 auto;
    width: 100%;
  }

  // Circle
  &:before {
    display: inline-block;
    position: relative;
    left: 50%;
    content: "";
    width: $size;
    height: $size;

    border-radius: $size;
    background-color: #b1cac6;
    flex: $size 0 0;

    @include media-breakpoint-up(xxl) {
      width: $size * $scale-font;
      height: $size * $scale-font;
      flex: $size * $scale-font 0 0;
    }
  }

  // Bar
  &:after {
    content: "";
    display: block;
    position: absolute;

    top: calc($size / 2 - 1px);
    z-index: -1;
    border-bottom: 2px solid #b1cac6;
    width: calc(200%);
  }

  &.first {
    &:after {
      left: 50%;
      width: calc(50%);
    }
  }

  &.last {
    &:after {
      display: none;
    }
  }
  
  // Done icon
  &.in-progress,
  &.done {
    color: #333333;
    
    .b-icon.bi {
      z-index: 2;
      fill: $primary;
    }
  }
  
  &.done {
    &:before {
      background-color: #cdfcb1;
    }
    
    &:after {
      border-color: #b1cac6;
      border-left-style: solid;
    }
  }
  
  .b-icon.bi {
    font-size: $size;
    margin: 0 auto;
    margin-top: 0;
    fill: $primary;

    @include media-breakpoint-up(xxl) {
      font-size: $size * $scale-font;
    }
  }
}

.timeline {
  display: flex;
  justify-content: center;
  overflow: hidden;
  li {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-bottom: 2rem;
    width: 20%;

    &:last-child {
      &:after {
        display: none;
      }
    }

    &.in-progress,
    &.done {
      color: #333333;

      .b-icon.bi {
        fill: $primary;
        @include media-breakpoint-up(xxl) {
          margin-top: -$size - 10px;
        }
      }

    }

    &.done {
      &:before {
        background-color: #cdfcb1;
      }

      &:after {
        border-color: #b1cac6;
        border-left-style: solid;
      }
    }

    .b-icon.bi {
      font-size: $size;
      margin: 0 auto;
      margin-top: -$size;

      @include media-breakpoint-up(xxl) {
        font-size: $size * $scale-font;
      }
    }

    &:before {
      display: inline-block;
      content: "";
      width: $size;
      height: $size;
      margin-right: auto;
      margin-left: auto;
      border-radius: $size;
      background-color: #b1cac6;
      flex: $size 0 0;

      @include media-breakpoint-up(xxl) {
        width: $size * $scale-font;
        height: $size * $scale-font;
        flex: $size * $scale-font 0 0;
      }
    }

    &:after {
      content: "";
      display: block;
      position: absolute;
      left: 50%;
      top: calc($size / 2 - 1px);
      z-index: -1;
      border-bottom: 2px solid #b1cac6;
      width: calc(100%);

      @include media-breakpoint-up(xxl) {
        top: calc(($size * $scale-font) / 2 - 1px);
      }
    }
  }
}
</style>
