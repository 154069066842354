<template>
  <div class="text-center">
    <div class="mt-5 mx-4 px-3">
      <h1
        class="text-primary display-3 mb-3 med-font"
        style="line-height: 1.1; font-weight: initial"
      >
        Hi <template v-if="!name">
          ...
        </template><template v-else>
          {{ name }}
        </template>, we’re so happy you’re here.
      </h1>
      <p class="sub-text mt-5">
        We're excited for you to start your Winona journey! Here's what you can
        expect next. If you have any questions, you can
        <br>
        message your <a href="/messages/doctor">physician</a> or chat with our
        <a
          href="javascript:;"
          @click="$emit('apc-redirect')"
        >Patient Care Team.</a>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: '',
    },
  },
};
</script>
